// Common function to initialize forms
function initializeForms() {
    const body = document.querySelector("body");

    // Registration form initialization
    if (body.dataset.jsPageId === "registration_form") {
        console.log("Identified Registration form");

        // Retry until grecaptcha is available
        const checkGreCaptchaAvailable = setInterval(function () {
            if (typeof grecaptcha !== "undefined") {
                clearInterval(checkGreCaptchaAvailable); // Stop checking once grecaptcha is available
                initializeRegistrationForm(); // Call your form initialization function
            } else {
                console.log("Waiting for grecaptcha to load...");
            }
        }, 500); // Check every 500 ms (0.5 second)
    }

    // Sign in form initialization
    if (body.dataset.jsPageId === "sign_in_form") {
        console.log("Identified Sign In form");
        const form = document.querySelector(".login-form");
        const submitButton = form.querySelector("input[type='submit']");
        const emailField = form.querySelector("input[type='email']");
        const passwordField = form.querySelector("input[type='password']");

        function checkFormFilled() {
            const emailFilled = emailField.value.trim() !== "";
            const passwordFilled = passwordField.value.trim() !== "";
            submitButton.disabled = !(emailFilled && passwordFilled);
        }

        emailField.addEventListener("input", checkFormFilled);
        passwordField.addEventListener("input", checkFormFilled);
        checkFormFilled();
    }

    // Password update form initialization
    if (body.dataset.jsPageId === "edit_password") {
        const form = document.querySelector(".password-form");
        const submitButton = form.querySelector("input[type='submit']");
        const currentPasswordField = document.getElementById("user_current_password");
        const newPasswordField = document.getElementById("user_password");
        const confirmPasswordField = document.getElementById("user_password_confirmation");

        function checkPasswordFormValidity() {
            const currentPasswordFilled = currentPasswordField.value.trim() !== "";
            const newPasswordFilled = newPasswordField.value.trim() !== "";
            const confirmPasswordFilled = confirmPasswordField.value.trim() !== "";

            // Enable submit button when all fields are filled and passwords match
            submitButton.disabled = !(
                currentPasswordFilled &&
                newPasswordFilled &&
                confirmPasswordFilled
            );
        }

        currentPasswordField.addEventListener("input", checkPasswordFormValidity);
        newPasswordField.addEventListener("input", checkPasswordFormValidity);
        confirmPasswordField.addEventListener("input", checkPasswordFormValidity);

        // Initialize button state on load
        checkPasswordFormValidity();
    }
}

// Function to initialize the registration form logic
function initializeRegistrationForm() {
    const form = document.getElementById("register_user");
    const submitButton = document.getElementById("create-account-button");
    const requiredFields = form.querySelectorAll("input[required]");
    const marketingOptIn = document.getElementById("marketing-opt-in");

    function checkFormValidity() {
        console.log("Checking form validity...");
        const allFilled = Array.from(requiredFields).every(input => input.value.trim() !== "");
        const isChecked = marketingOptIn.checked;
        const recaptchaResponse = grecaptcha.getResponse(); // Get reCAPTCHA response

        // Enable submit only if all fields are filled, checkbox is checked, and reCAPTCHA is verified
        submitButton.disabled = !(allFilled && isChecked && recaptchaResponse.length > 0);
    }

    // Add event listeners to required fields
    requiredFields.forEach(input => {
        input.addEventListener("input", checkFormValidity);
    });

    // Add event listener to marketing opt-in checkbox
    marketingOptIn.addEventListener("change", checkFormValidity);

    // Callback for reCAPTCHA success
    window.onRecaptchaSuccess = function () {
        console.log("reCAPTCHA successfully completed.");
        checkFormValidity();
    };

    // Callback for reCAPTCHA expiration
    window.onRecaptchaExpired = function () {
        console.log("reCAPTCHA expired.");
        submitButton.disabled = true;
    };

    // Initial form validation check
    checkFormValidity();
}

// Listen for turbolinks:load
document.addEventListener("turbolinks:load", initializeForms);